.headerStuff {
  padding: 0;
  margin: 0;
  margin-top:-5%;
}

.text-gradient {
  background: linear-gradient(to right, #30CFD0, #c43ad6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  
}
body {
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  font-feature-settings: 'liga' 1, 'calt' 1;
  background-color: #efefef;
}
a {
  all: unset;
}
.mySwiperWork {
  -webkit-transition-timing-function:linear!important;
  -o-transition-timing-function:linear!important;
  transition-timing-function:linear!important;
 }
/* ----------- Navbar Section Styles ----------- */
.navBarUltraMainContainer {
  width: 100%;
}
.navBarMobileMainContainer{
  display:none;
}
.menu-container {
  display: none;
}
.navBarMainContainer {
  width: 80%;
  margin-left:10%;
  display:flex;
  flex-direction: row;
  height: 3.5rem;
  position: fixed;
  top: 0;
  /* border-style: solid;
  border-color:black; */
  background-color: #efefef;
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1.5px;
  z-index:500;
}

.navBarLogoContainer {
  display:flex;
  width: 20%;
  align-items: center;
}
.navBarLogo {
  width:18%;
  margin-bottom: 1%;
}
.navBarPagesOutterLinkContainer {
  width:20%;
  display: flex;
  flex-direction: row;
}

.navBarPagesLinkContainerInner {
  width: 80%;
  min-height: 100%;
  display:flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left:0;
  padding-left:0;
}
.navBarIndividualLinksButton {
  width:auto;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-style: none;
  background-color: transparent;
  padding-left:0;
  padding-right: 0;
  margin-right: 0;
}
.navBarIndividualLinksButton:hover {
  cursor: pointer;
}

.navBarIndividualLinksButtonOutter {
  width:100%;
  height: 100%;
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-style: none;
  background-color: transparent;
  padding-left:0;
  padding-right: 0;
}
.navBarLinkStyleOuter {
  margin-right: 0;
  padding-right:0;
  min-width: 30%;
  max-width: 30%;
}
.navBarIndividualLinksButtonOutter {
  cursor: pointer;
}
.navBarArrowContainer{
  min-width:30%;
  max-width: 30%;
  padding-right:0;
  margin-right: 0;
}
.navBarArrow {
  width:50%;
  margin-top:-15%;
  margin-left:-40%;
}
/* Button Specific Styles */
.navBarButtonContainer{
  width:18%;
  display:flex;
  justify-content: center;
  align-items: center;
}

.navBarContactButton {
  border-radius:10px;
  border-style: none;
  background-color:#a1f6c8;
  width:100%;
}
.navBarLinkStyleContactButton {
  width:100%;
}
.navBarContactButton:hover {
  border-radius:10px;
  border-style: none;
  background-color:rgba(27,7,44,1);
  width:100%;
  cursor: pointer;
}
.navBarIndividualLinksButtonOutter:hover .navBarContactButton{
  background-color:rgba(27,7,44,1);
}
/* Text styles */
.navBarLogoText {
  margin:0;
  font-size:1.8em;
  font-weight: 600;
  top:100%;
  padding-right:0%;
}
.navBarPagesLinkHeader{
  color: #9d8fa4;
  font-size: 1.15em;
  padding-right:1em;
  font-weight: 400;
  margin-left:0;
  padding-left:0;
}
.navBarIndividualLinksButton:hover .navBarPagesLinkHeader{
  color: rgba(27,7,44,1);
}
.navBarPagesLinkHeaderOutter {
  font-size:1.15em;
  font-weight: 500;
  margin:0;
  color: #9d8fa4;
  padding-bottom: 17.5%;
  padding-top:17.5%;
}
.navBarPagesLinkHeaderOutter:hover {
  color: rgba(27,7,44,1);
}
.navBarButtonText {
  color: #2c2c2c;
  margin:0;
  font-size:1.2em;
  font-weight: 550;
  padding-top:6%;
  padding-bottom: 6%;
  padding-right: 10.5%;
  padding-left: 10.5%;
}
.navBarContactButton:hover .navBarButtonText {
  color: #e6e6e6;
}
/* HR Line Styles */
.navBarHr {
  margin:0;
  margin-right: 0.75em;
  background-color: rgba(27,7,44,1);
  border-color: rgba(27,7,44,1);
  height:60%;
}
/* ----------- End of Navbar Styles ---------- */

/* ----------- Landing Page Styles ----------- */
.landingPageMainContainer {
  width:80%;
  margin-left:10%;
}
.landingPageRowContainer {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding-bottom:13.5%;
}
.landingPageRowContainerLeftColumn {
  padding-top:13%;
  width:40%;
  padding-bottom: 4%;
}
.landignPageRowButtons {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.landingPageLinkStyle{
  width:45%;
}
.landignPageContactButton {
  border-radius:10px;
  border-style: none;
  background-color:#a1f6c8;
  width:100%;
  padding-top:6%;
  padding-bottom: 6%;
  border-color: #a1f6c8;
  border-style:solid;
  border-width: 2px;
}
.landignPageContactButton:hover {
  border-radius:10px;
  border-style:solid;
  border-width: 2px;
  border-color: rgba(27,7,44,1);
  background-color:rgba(27,7,44,1);
  cursor: pointer;
}
.landingPagePortfolioButton {
  border-radius:10px;
  border-color: #8716e8;
  border-style:solid;
  border-width: 2px;
  width:100%;
  display:flex;
  flex-direction: row;
  padding-top:6%;
  padding-bottom: 6%;
  justify-content: center;
}
.landingPagePortfolioButton:hover  {
  color: rgba(27,7,44,1);
  cursor: pointer;
}
.landingPagePortfolioTextContainer {
  width:auto;
}
.landingPageArrowContainer {
  width:8%;
}
.ladningPageArrow {
  margin-top:-10%;
  width:100%;
}
.landingPagePortfolioButton:hover .landingPageArrowContainer {
  width:8%;
}
.landingPageEthImage {
  width:75%;
  margin-left:25%;
}
/* Landing Page Ethereum Animation Styles */
.landingPageRowContainerRightColumn {
  padding-top:9%;
  width:55%;
  padding-bottom: 0%;
}
@keyframes etherAnimation {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10%);
  }
  100% {
    transform: translateY(0);
  }
}
.landingPageBigEtherStyles {
  position: absolute;
  margin-top:3.5%;
  width:17.5%;
  margin-left:17.5%;
  animation: 3.5s ease-in-out 1.5s infinite etherAnimation;
  z-index:3;
}
@keyframes behindCoinAnimation {
  0% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(-52.5%);
  }
  100% {
    transform: translateY(-5%);
  }
}
.landingPageBehindCoinStyles {
  position: absolute;
  width:5%;
  margin-top:6%;
  margin-left:27%;
  animation: 3.5s ease-in-out 1.5s infinite behindCoinAnimation;
  z-index: 1;
}
@keyframes bigSingleCoinAnimation {
  0% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(-52.5%);
  }
  100% {
    transform: translateY(-5%);
  }
}
.landingPageBigSingleCoinStyles {
  position: absolute;
  width:7%;
  margin-top:14.5%;
  margin-left:28%;
  animation: 3.5s ease-in-out 1.5s infinite bigSingleCoinAnimation;
  z-index:4;
}
@keyframes mediumSingleCoinAnimation {
  0% {
    transform: translateY(-5%);
  }
  50% {
    transform: translateY(-52.5%);
  }
  100% {
    transform: translateY(-5%);
  }
}
.landingPageMediumSingleCoinStyles {
  position: absolute;
  width:5.5%;
  margin-top:13%;
  margin-left:13.5%;
  animation: 3.5s ease-in-out 1.5s infinite mediumSingleCoinAnimation;
  z-index:4;
}

/* Text Styles */
.landingPageMainHeader {
 font-size: 2.5em;
 font-weight:600;
}
.landingPageMainHeaderSpan{
  background: linear-gradient(301deg, #8716e8, #2c2c2c 49%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-top:0;
  padding-top:0;
}
.landingPageDescriptionText {
  font-weight: 400;
  font-size:1.15em;
  color: #9d8fa4;
}
.landingPageSmallesHeader {
  font-size:1em;
  color: #8716e8;
}
/* Button text styles */
.landingPageContactButtonText {
  font-size:1.3em;
  font-weight: 600;
  margin:0;
  color: #2c2c2c;
}
.landignPageContactButton:hover .landingPageContactButtonText {
  color: #e6e6e6;
}
.landingPagePortfolioTextHeader{
  font-size:1.3em;
  font-weight: 600;
  margin:0;
  color: #9d8fa4;
  padding-bottom: 0;
}
.landingPagePortfolioButton:hover .landingPagePortfolioTextHeader {
  color: rgba(27,7,44,1);
}
/* Media queries Landing Page Styles */
@media only screen and (max-width: 480px) {
  .landingPageMainContainer {
    width:90%;
    margin-left:5%;
  }
  .landingPageRowContainer {
    display:flex;
    flex-direction: column;
  }
  .landingPageRowContainerLeftColumn {
    width:100%;
  }
  .landingPageRowContainerRightColumn {
    padding-top:9%;
    width:100%;
    padding-bottom: 75%;
  }
  .landingPageBigEtherStyles {
    position: absolute;
    margin-top:3.5%;
    width:40%;
    margin-left:25.5%;
    animation: 3.5s ease-in-out 1.5s infinite etherAnimation;
    z-index:3;
  }
  .landingPageBehindCoinStyles {
    position: absolute;
    width:10%;
    margin-top:10%;
    margin-left:48.5%;
    animation: 3.5s ease-in-out 1.5s infinite behindCoinAnimation;
    z-index: 1;
  }
  .landingPageBigSingleCoinStyles {
    position: absolute;
    width:15%;
    margin-top:25%;
    margin-left:49%;
    animation: 3.5s ease-in-out 1.5s infinite bigSingleCoinAnimation;
    z-index:4;
  }
  .landingPageMediumSingleCoinStyles {
    position: absolute;
    width:12%;
    margin-top:20%;
    margin-left:25.5%;
    animation: 3.5s ease-in-out 1.5s infinite mediumSingleCoinAnimation;
    z-index:4;
  }
  /* Text Styles */
  .landingPageMainHeader {
    font-size: 2em;
    font-weight:600;
    text-align: center;
  }
  .landingPageDescriptionText {
    font-weight: 400;
    font-size:1em;
    text-align: center;
    color: #9d8fa4;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 481px){
  .landingPageMainContainer {
    width:80%;
    margin-left:10%;
  }
  .landingPageRowContainer {
    display:flex;
    flex-direction: column;
    padding-bottom: 17.5%;
  }
  .landingPageRowContainerLeftColumn {
    width:100%;
  }
  .landingPageRowContainerRightColumn {
    padding-top:9%;
    width:100%;
    padding-bottom: 75%;
  }
  .landingPageBigEtherStyles {
    position: absolute;
    margin-top:7.5%;
    width:35%;
    margin-left:22.5%;
    animation: 3.5s ease-in-out 1.5s infinite etherAnimation;
    z-index:3;
  }
  .landingPageBehindCoinStyles {
    position: absolute;
    width:10%;
    margin-top:13.5%;
    margin-left:41.5%;
    animation: 3.5s ease-in-out 1.5s infinite behindCoinAnimation;
    z-index: 1;
  }
  .landingPageBigSingleCoinStyles {
    position: absolute;
    width:15%;
    margin-top:27.5%;
    margin-left:45.5%;
    animation: 3.5s ease-in-out 1.5s infinite bigSingleCoinAnimation;
    z-index:4;
  }
  .landingPageMediumSingleCoinStyles {
    position: absolute;
    width:11%;
    margin-top:20%;
    margin-left:22.5%;
    animation: 3.5s ease-in-out 1.5s infinite mediumSingleCoinAnimation;
    z-index:4;
  }
  /* Text Styles */
  .landingPageMainHeader {
    font-size: 2em;
    font-weight:600;
    text-align: center;
  }
  .landingPageDescriptionText {
    font-weight: 400;
    font-size:1em;
    text-align: center;
    color: #9d8fa4;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1025px){
  .landingPageMainContainer {
    width:80%;
    margin-left:10%;
  }
  .landingPageRowContainer {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    padding-bottom:20%;
  }
  .landingPageRowContainerLeftColumn {
    padding-top:22.5%;
    width:40%;
    padding-bottom: 4%;
  }
  .landingPageEthImage {
    width:75%;
    margin-left:25%;
  }
  /* Landing Page Ethereum Animation Styles */
  .landingPageRowContainerRightColumn {
    padding-top:15%;
    width:55%;
    padding-bottom: 0%;
  }
  .landingPageBigEtherStyles {
    position: absolute;
    margin-top:3.5%;
    width:20%;
    margin-left:20%;
    animation: 3.5s ease-in-out 1.5s infinite etherAnimation;
    z-index:3;
  }
  .landingPageBehindCoinStyles {
    position: absolute;
    width:7%;
    margin-top:7%;
    margin-left:30%;
    animation: 3.5s ease-in-out 1.5s infinite behindCoinAnimation;
    z-index: 1;
  }
  .landingPageBigSingleCoinStyles {
    position: absolute;
    width:8.5%;
    margin-top:14.5%;
    margin-left:32%;
    animation: 3.5s ease-in-out 1.5s infinite bigSingleCoinAnimation;
    z-index:4;
  }

  .landingPageMediumSingleCoinStyles {
    position: absolute;
    width:6.5%;
    margin-top:13%;
    margin-left:16.5%;
    animation: 3.5s ease-in-out 1.5s infinite mediumSingleCoinAnimation;
    z-index:4;
  }
  
  /* Text Styles */
  .landingPageMainHeader {
   font-size: 2.5em;
   font-weight:600;
  }
  .landingPageMainHeaderSpan{
    background: linear-gradient(301deg, #8716e8, #2c2c2c 49%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top:0;
    padding-top:0;
  }
  .landingPageDescriptionText {
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
  }
  .landingPageSmallesHeader {
    font-size:1em;
    color: #8716e8;
  }
  /* Button text styles */
  .landingPageContactButtonText {
    font-size:1.3em;
    font-weight: 600;
    margin:0;
    color: #2c2c2c;
  }
  .landingPagePortfolioTextHeader{
    font-size:1.3em;
    font-weight: 600;
    margin:0;
    color: #9d8fa4;
    padding-bottom: 0;
  }
}
/* ----------- End of Landing Page Styles ---------- */

/* ----------- Services Styles ----------- */
.servicesMainContainer {
  width: 80%;
  margin-left:10%;
  margin-top:4%;
  border-radius: 20px;
  background-color: #e6e6e6;
  padding-top:2%;
  padding-bottom:2%;
}
.servicesTopContainer {
  width:100%;
  text-align: center;
}
.servicesRowsContainer {
  margin-top:3%;
  width:80%;
  margin-left:10%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.servicesRowsContainerLeftColumn {
  width:48%;
  border-radius: 20px;
  height:auto;
  /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
}

.servicesRowsContainerRightColumn {
  width:48%;
  border-radius: 20px;
  height:auto;
  /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
}
.servicesButtonContainer{
  width:80%;
  margin-left:10%;
  margin-top:3%;
  display:flex;
  justify-content: center;
  align-items: center;
  margin-bottom:0;
}
.servicesButtonLinkStyles {
  width:100%;
}
.servicesBookAnAuditButton{
  border-radius:10px;
  border-color: #8716e8;
  border-style:solid;
  border-width: 2px;
  width:20%;
  margin-left:40%;
  display:flex;
  flex-direction: row;
  padding-top:1.25%;
  padding-bottom: 1.25%;
  justify-content: center;
}
.servicesBookAnAuditButton:hover{
  cursor: pointer;
}
/* Text styles */
.servicesSectionHeader {
  font-size:2em;
  font-weight: 700;
  margin-top:0;
}
.servicesDescritptionText {
  width:60%;
  margin-left:20%;
  font-weight: 400;
  font-size:1.15em;
  color: #9d8fa4;
}
.servicesCardHeader {
  width:90%;
  margin-left:5%;
  font-weight: 600;
  font-size:1.3em;
  color: #e6e6e6;
  margin-top:0;
}
.servicesCardDescriptionText{
  width:90%;
  margin-left:5%;
  font-weight: 400;
  font-size:1em;
  color: #e6e6e6;
  padding-bottom: 3%;
}
.servicesBookAnAuditButtonText{
  font-size:1.3em;
  font-weight: 600;
  margin:0;
  color: #9d8fa4;
  padding-bottom: 0;
}
.servicesBookAnAuditButton:hover .servicesBookAnAuditButtonText {
  color: rgba(27,7,44,1);
}
/* Icons Styles */
.serviceMagnifyingGlass {
  color:#e6e6e6;
  width:7%;
  margin-left:5%;
  padding-top:5%;
}
.serviceHandShake {
  color:#e6e6e6;
  width:7.5%;
  margin-left: 5%;
  padding-top:5%;
}
/* Media queries Services Styles */
@media only screen and (max-width: 480px) {
  .servicesMainContainer {
    width:90%;
    margin-left:5%;
  }
  .servicesRowsContainer {
    display:flex;
    flex-direction: column;
    width:90%;
    margin-left:5%;
  }
  .servicesRowsContainerLeftColumn {
    width:100%;
    border-radius: 20px;
    height:auto;
    /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
    margin-top:5%;
  }
  .servicesRowsContainerRightColumn {
    width:100%;
    border-radius: 20px;
    height:auto;
    /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
    margin-top:5%;
  }
  .servicesButtonContainer{
    width:90%;
    margin-left:5%;
    margin-top:5%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0;
    padding-bottom: 3%;
  }
  .servicesButtonLinkStyles {
    width:100%;
  }
  .servicesBookAnAuditButton{
    border-radius:10px;
    border-color: #8716e8;
    border-style:solid;
    border-width: 2px;
    width:52.5%;
    margin-left:23.75%;
    display:flex;
    flex-direction: row;
    padding-top:2.5%;
    padding-bottom: 2.5%;
    justify-content: center;
  }
  /* Text Styles */
  .servicesDescritptionText {
    width:90%;
    margin-left:5%;
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
    text-align: center;
  }
  /* Text styles */
  .servicesSectionHeader {
    width:90%;
    margin-left:5%;
    font-size:2em;
    font-weight: 700;
    margin-top:1.5%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 481px){
  .servicesMainContainer {
    width:80%;
    margin-left:10%;
  }
  .servicesRowsContainer {
    display:flex;
    flex-direction: column;
    width:90%;
    margin-left:5%;
  }
  .servicesRowsContainerLeftColumn {
    width:100%;
    border-radius: 20px;
    height:auto;
    /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
    margin-top:5%;
  }
  .servicesRowsContainerRightColumn {
    width:100%;
    border-radius: 20px;
    height:auto;
    /* background: linear-gradient(71deg, #1b072c, #9d8fa4, #1b072c); */
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 110%);
    margin-top:5%;
  }
  .servicesButtonContainer{
    width:70%;
    margin-left:15%;
    margin-top:5%;
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom:0;
    padding-bottom: 3%;
  }
  .servicesButtonLinkStyles {
    width:100%;
  }
  .servicesBookAnAuditButton{
    border-radius:10px;
    border-color: #8716e8;
    border-style:solid;
    border-width: 2px;
    width:52.5%;
    margin-left:23.75%;
    display:flex;
    flex-direction: row;
    padding-top:2.5%;
    padding-bottom: 2.5%;
    justify-content: center;
  }
  /* Text Styles */
  .servicesDescritptionText {
    width:90%;
    margin-left:5%;
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
    text-align: center;
  }
  /* Text styles */
  .servicesSectionHeader {
    width:90%;
    margin-left:5%;
    font-size:2em;
    font-weight: 700;
    margin-top:1.5%;
  }
  /* Icons Styles */
.serviceMagnifyingGlass {
  color:#e6e6e6;
  width:4.5%;
  margin-left:5%;
  padding-top:5%;
}
.serviceHandShake {
  color:#e6e6e6;
  width:5%;
  margin-left: 5%;
  padding-top:5%;
}
}
/* ----------- End of Services Styles ---------- */

/* ----------- Work Styles ----------- */
.workContainer {
  width: 80%;
  margin-left:10%;
  margin-top:6%;
}

/* Description Container Styles */
.workContainerDescriptionContainer {
  width: 100%;
  display:flex;
  flex-direction: row;
}
.workContainerDescriptionContainerLeftColum {
  width: 30%;
}
.workContainerDescriptionContainerRightColum {
  width:55%;
  margin-left:15%;
}
.workContainerStatsMainContainer {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.workContainerStatsMainContainerLeftColumn {
  width:66%;
}

.workContainerStatsMainContainerRightColumn {
  width:30%;
  border-radius: 20px;
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
}
/* Top Row Styles */
.workContainerTopRow {
  max-width:100%;
  height: auto;
  background-color: #e6e6e6;
  border-radius: 15px;
}
.workSliderMainContainer {
  width:100%;
  height:30%;
  padding-bottom: 2%;
  padding-top:2%;
}
.swiper-wrapper{
  transition-timing-function: linear !important;
}
.workSlideContainer {
  display:flex;
  border-style: solid;
  border-color:#e6e6e6;
  height:5em;
  justify-content: center;
  align-items: center;
}
.workSwiperContainerBox{
  border-style:solid;
  border-color:red;
  height:7.5rem;
}
.workSwiperSmallContainer {
  height:2rem;
  border-style:solid;
  border-color:blue;
}
.workReviewsCardContainer {
  border-style: solid;
  border-color:green;
}
/* Bottom Row Styles */
.workContainerBottomRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  margin-top:1.5%;
  height:8em;
}
.workContainerBottomRowLeftColumn {
  width:48%;
  background-color: #e6e6e6;
  border-radius: 15px;
}
.workContainerBottomRowRightColumn {
  width:48%;
  background-color: #e6e6e6;
  border-radius: 15px;
}

/* Inner audit container styles */
.workContainerAuditStatsInnerMainContainer {
  width:90%;
  margin-left:5%;
  margin-top:6.5%;
}
.workContainerAuditStatsInnerMainContainerFirstRow {
  width: 100%;
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  padding-bottom: 7%;
}
.workContainerAuditStatsSmallContainer {
  width:42.5%;
  background-color: #e6e6e6;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.workSectionSpanOneContainer {
  width:17.5%;
}
.workSectionSpanOne {
  background-color:rgba(135,22,232,1);
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
.workSectionSpanTwo {
  background-color:rgba(27,7,44,1);
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
.workSectionSpanThree {
  background-color:#a1f6c8;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
.workSectionSpanFour {
  background-color:rgba(157,143,164,1);
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
.workSectionSpanFive {
  background-color:#2c2c2c;
  height: 0.4rem;
  width: 0.4rem;
  border-radius: 50%;
  margin: auto;
  display: block;
}
/* Text styles */
.workSectionHeader {
  font-size:2em;
  font-weight: 700;
  margin-top:-1.5%;
  padding-top:0;
}
.workSectionDescription {
  font-size:1.15em;
  color: #9d8fa4;
  font-weight: 400;
  text-align: right;
  margin-top:0;
}
.workContainerCardsHeaders {
  font-size: 1.3em;
  font-weight: 600;
  margin:0;
  padding:2.5%;
  padding-bottom: 0;
  color:#2c2c2c;
}
.workContainerCardsHeadersBottomColumns {
  font-size: 1.3em;
  font-weight: 600;
  margin:0;
  padding-left:5%;
  padding-top:5%;
  padding-bottom: 3%;
  color:#2c2c2c;
}
.workSectioBottomBoxesDesc {
  font-size: 1em;
  font-weight: 400;
  margin-top:0;
  padding-left:5%;
  color:#2c2c2c;
}
.workContainerAuditStatsMainHeader {
  font-size: 1.3em;
  font-weight: 600;
  margin:0;
  padding-top:5%;
  padding-left: 5%;
  padding-bottom: 3%;
  color:#e6e6e6;
}
.workContainerAuditStatsHeader {
  font-size: 1.15em;
  font-weight: 500;
  margin:0;
  padding-left: 5%;
  padding-bottom: 3%;
  color:#e6e6e6;
}

/* Audit stats text styles */
.workContainerAuditStatsSmallContainerHeader {
  font-size:1em;
  font-weight: 500;
  color:#2c2c2c;
  padding-top:5%;
  padding-bottom: 5%;
  margin:0;
}
/* Media queries Services Styles */
@media only screen and (max-width: 480px) {
  .workContainer {
    width: 90%;
    margin-left:5%;
    margin-top:6%;
  }
  .workContainerDescriptionContainer {
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  .workContainerDescriptionContainerLeftColum {
    width: 100%;
  }
  .workContainerDescriptionContainerRightColum {
    width:100%;
    margin-left:0%;
  }
  .workContainerTopRow {
    max-width:100%;
    height: auto;
    background-color: #e6e6e6;
    border-radius: 15px;
    margin-top:3%;
  }
  .workSlideContainer {
    display:flex;
    border-style: solid;
    border-color:#e6e6e6;
    height:4em;
    justify-content: center;
    align-items: center;
  }
  .VaultodyLogo {
    width: 85%;
  }
  .workContainerStatsMainContainer {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .workContainerStatsMainContainerLeftColumn {
    width:100%;
  }
  .workContainerStatsMainContainerRightColumn {
    width:100%;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:5%;
  }
  /* Bottom Row Styles */
  .workContainerBottomRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top:5%;
    height:8em;
  }
  .workContainerBottomRowLeftColumn {
    width:48%;
    background-color: #e6e6e6;
    border-radius: 15px;
  }
  .workContainerBottomRowRightColumn {
    width:48%;
    background-color: #e6e6e6;
    border-radius: 15px;
  }
  /* Text Styles */
  .workSectionHeader {
    font-size:2em;
    font-weight: 700;
    margin-top:-1.5%;
    padding-top:0;
    text-align: center;
  }
  .workSectionDescription {
    font-size:1.15em;
    color: #9d8fa4;
    font-weight: 400;
    text-align: center;
    margin-top:0;
  }
  .workContainerCardsHeaders {
    font-size: 1.15em;
    font-weight: 600;
    margin:0;
    padding:2.5%;
    padding-bottom: 0;
    color:#2c2c2c;
  }
  .workContainerCardsHeadersBottomColumns {
    font-size: 1.15em;
    font-weight: 600;
    margin:0;
    padding-left:5%;
    padding-top:5%;
    padding-bottom: 3%;
    color:#2c2c2c;
  }
  .workContainerAuditStatsMainHeader {
    font-size: 1.3em;
    font-weight: 600;
    margin:0;
    padding-top:5%;
    padding-left: 0%;
    padding-bottom: 2%;
    text-align: center;
    color:#e6e6e6;
  }
  .workContainerAuditStatsHeader {
    font-size: 1.15em;
    font-weight: 500;
    margin:0;
    padding-left: 0%;
    padding-bottom: 0%;
    color:#e6e6e6;
    text-align: center;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 481px){
  .workContainer {
    width: 80%;
    margin-left:10%;
    margin-top:6%;
  }
  .workContainerDescriptionContainer {
    width: 100%;
    display:flex;
    flex-direction: column;
  }
  .workContainerDescriptionContainerLeftColum {
    width: 100%;
  }
  .workContainerDescriptionContainerRightColum {
    width:100%;
    margin-left:0%;
  }
  .workContainerTopRow {
    max-width:100%;
    height: auto;
    background-color: #e6e6e6;
    border-radius: 15px;
    margin-top:3%;
  }
  .workSlideContainer {
    display:flex;
    border-style: solid;
    border-color:#e6e6e6;
    height:4em;
    justify-content: center;
    align-items: center;
  }
  .VaultodyLogo {
    width: 70%;
  }
  .workContainerStatsMainContainer {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .workContainerStatsMainContainerLeftColumn {
    width:100%;
  }
  .workContainerStatsMainContainerRightColumn {
    width:100%;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:5%;
  }
  /* Bottom Row Styles */
  .workContainerBottomRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top:5%;
    height:8em;
  }
  .workContainerBottomRowLeftColumn {
    width:48%;
    background-color: #e6e6e6;
    border-radius: 15px;
  }
  .workContainerBottomRowRightColumn {
    width:48%;
    background-color: #e6e6e6;
    border-radius: 15px;
  }
  /* Text Styles */
  .workSectionHeader {
    font-size:2em;
    font-weight: 700;
    margin-top:-1.5%;
    padding-top:0;
    text-align: center;
  }
  .workSectionDescription {
    font-size:1.15em;
    color: #9d8fa4;
    font-weight: 400;
    text-align: center;
    margin-top:0;
  }
  .workContainerAuditStatsMainHeader {
    font-size: 1.3em;
    font-weight: 600;
    margin:0;
    padding-top:5%;
    padding-left: 0%;
    padding-bottom: 2%;
    text-align: center;
    color:#e6e6e6;
  }
  .workContainerAuditStatsHeader {
    font-size: 1.1em;
    font-weight: 500;
    margin:0;
    padding-left: 0%;
    padding-bottom: 0%;
    color:#e6e6e6;
    text-align: center;
  }
}
/* ----------- End of Work Styles ---------- */

/* ----------- Why Me Styles ---------- */
.whyMeMainContainer {
  width: 80%;
  margin-left: 10%;
  margin-top:3%;
}
.whyMeTopContainer {
  width:100%;
  text-align: center;
}
.whyMeRowMainCointainer {
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.whyMeLeftColumn {
  width:30%;
  height: 54vh;
  border-radius: 20px;
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
  margin-top:3%;
}

.whyMeMiddleColumn {
  width:30%;
  height: 54vh;
  border-radius: 20px;
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
}

.whyMeRightColumn {
  width:30%;
  height: 54vh;
  border-radius: 20px;
  background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
  margin-top:6%;
}
.whyMeMultipleStarsContainer {
  width:80%;
  margin-left:10%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top:5%;
}
/* Text styles */
.whyMeSectionHeader {
  font-size:2em;
  font-weight: 700;
  margin-top:0;
}
.whyMeCardMainHeader {
  font-size:1.7em;
  font-weight: 600;
  text-align: center;
  color: #e6e6e6;
}
.whyMeCardDescText {
  width:80%;
  margin-left:10%;
  padding-top:0;
  margin-top:0;
  font-size:1.15em;
  font-weight: 500;
  text-align: center;
  color: #e6e6e6;
}
/* Icons Styles */
.whyMeDetectiveIcon {
  width:6%;
  margin-top:0;
  margin-bottom: 0;
  padding:0;
  color:#e6e6e6;
  margin-left:47%;
  margin-top:0.5%;
}
.whyMeSingStarIcon {
  width:8%;
  margin-top:0;
  margin-bottom: 0;
  padding:0;
  color:#e6e6e6;
}
.whyMePiggyBankIcon {
  width:10%;
  margin-top:0;
  margin-bottom: 0;
  padding:0;
  color:#e6e6e6;
  margin-left:45%;
  margin-top:1%;
}
/* Why Me Media Query Styles */
@media only screen and (max-width: 480px) {
  .whyMeMainContainer {
    width: 90%;
    margin-left: 5%;
    margin-top:3%;
  }
  .whyMeRowMainCointainer {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .whyMeLeftColumn {
    width:100%;
    height: 48vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:3%;
  }
  
  .whyMeMiddleColumn {
    width:100%;
    height: 48vh;
    margin-top:6%;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
  }
  
  .whyMeRightColumn {
    width:100%;
    height: 48vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:6%;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 481px){
  .whyMeMainContainer {
    width: 80%;
    margin-left: 10%;
    margin-top:3%;
  }
  .whyMeRowMainCointainer {
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .whyMeLeftColumn {
    width:100%;
    height: 40vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:3%;
  }
  
  .whyMeMiddleColumn {
    width:100%;
    height: 40vh;
    margin-top:6%;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
  }
  
  .whyMeRightColumn {
    width:100%;
    height: 40vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:6%;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1025px){
  .whyMeLeftColumn {
    width:30%;
    height: 41vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:3%;
  }
  
  .whyMeMiddleColumn {
    width:30%;
    height: 41vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
  }
  
  .whyMeRightColumn {
    width:30%;
    height: 41vh;
    border-radius: 20px;
    background: linear-gradient(145deg, rgba(27,7,44,1) 18%, rgba(135,22,232,1) 92%, rgba(157,143,164,1) 105%);
    margin-top:6%;
  }
}
/* ----------- End of Why Me Styles ---------- */

/* ----------- Reviews Styles ----------- */
.reviewsContainer {
  width: 80%;
  margin-left:10%;
  margin-top:3%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}

.reviewsLeftColumnContainer {
  /* width:33%; */
  width: 45%;
}
.reviewsRightColumnContainer{
  /* width: 64%; */
  width: 34%;
}
.reviewsCardContainer {
  width:100%;
  border-radius: 20px;
  background-color:#e6e6e6;
  height: 60vh;
}
.reviewNameAndTwitterHandleContainer {
 width:85%;
 margin-left:7.5%;
 margin-top:10%;
 display:flex;
 flex-direction: row;
}
.reviewNameAndTwitterHandleContainerLeftColumn{
  width:80%;
}
.reviewNameAndTwitterHandleContainerRightColumn{
  width:20%;
  display: flex;
  justify-content:flex-end;
}
.reviewsCompanyLogoLink {
  width:100%;
  display: flex;
  justify-content: flex-end;
}
.reviewsCompanyLogoLink:hover {
  cursor: pointer;
}
.reviewsCompanyLogoImg {
  width:50%;
}
/* Swiper styles */
.swiperSmallContainer {
  width:100%;
  margin: 0 auto;
}
.swiperContainerBox {
  position: relative;
}
.swiperContainerBox .swipperArrow {
  position: absolute;
  top: 50%;
  margin-top: -5px;
}
.swipperArrow {
  position: absolute;
  top: 50%;
  margin-top: -5px;
  z-index:100;
  background-color: rgba(157,143,164,0.2);
  width:2.5rem;
  height:2.5rem;
  border-radius: 50%;
  border-style: none;
  display: flex;
  justify-content:center;
  align-items: center;
}
.swipperArrow:hover {
  cursor: pointer;
  background-color: rgba(157,143,164,0.3);
}
.swiperContainerBox .swipperArrow.arrowLeft {
  left: -2.5%;
}
.swiperContainerBox .swipperArrow.arrowRight {
  right: -2.5%;
}
.reviewsAngleLeftIcon {
  color:#e6e6e6;
  width:70%;
}
.swipperArrow:hover .reviewsAngleLeftIcon{
  color: #efefef;
}
.reviewsAngleRightIcon {
  color:#e6e6e6;
  width:70%;
}
.swipperArrow:hover .reviewsAngleRightIcon{
  color: #efefef;
}
/* Text styles */
.reviewsMainHeader{
  font-size:2em;
  font-weight: 700;
  margin:0;
}
.reviewsActualReviewText {
  width: 85%;
  margin-top:7.5%;
  margin-left:7.5%;
  font-weight: 400;
  font-size:1em;
  color: #9d8fa4;
  height: 50%;
}
.reviewNameAndTwitterHandleContainerLeftColumnName{
  font-size:1.15em;
  font-weight: 400;
  color:#9d8fa4;
  margin-top:0;
  margin-bottom: 1%;
}
.reviewNameAndTwitterHandleContainerLeftColumnHandle{
  font-size:1em;
  font-weight:400;
  margin-top:0;
  color:#2c2c2c;
}
.reviewsCompanyRepresentativeLink:hover {
  cursor: pointer;
}
/* Icons Styles */
.reviewsQuotesIcon {
  margin-top:7.5%;
  margin-left:7.5%;
  width:5%;
  color:#8716e8;
}
/* Review Media Query Styles */
@media only screen and (max-width: 376px) {
  .reviewsContainer {
    width: 90%;
    margin-left:5%;
    margin-top:3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .reviewsLeftColumnContainer {
    width:100%;
  }
  .reviewsRightColumnContainer{
    width: 100%;
  }
  .swiperContainerBox {
    position: relative;
    margin-top:5%;
  }
  .reviewsCardContainer {
    width:100%;
    border-radius: 20px;
    background-color:#e6e6e6;
    height: 62.5vh;
  }
  .reviewNameAndTwitterHandleContainer {
    width:85%;
    margin-left:7.5%;
    margin-top:40%;
    display:flex;
    flex-direction: row;
   }
  /* Text styles */
  .reviewsMainHeader{
    font-size:2em;
    font-weight: 700;
    margin:0;
    text-align: center;
  } 
  .reviewsActualReviewText {
    width: 85%;
    margin-top:5%;
    margin-left:7.5%;
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
    height: 45%;
  }
  .reviewNameAndTwitterHandleContainerLeftColumnHandle{
    font-size:1.15em;
    font-weight:400;
    margin-top:0;
    color:#2c2c2c;
  }
}

@media only screen and (max-width: 480px) and (min-width: 377px) {
  .reviewsContainer {
    width: 90%;
    margin-left:5%;
    margin-top:3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .reviewsLeftColumnContainer {
    width:100%;
  }
  .reviewsRightColumnContainer{
    width: 100%;
  }
  .swiperContainerBox {
    position: relative;
    margin-top:5%;
  }
  .reviewsCardContainer {
    width:100%;
    border-radius: 20px;
    background-color:#e6e6e6;
    height: 56.5vh;
  }
  .reviewNameAndTwitterHandleContainer {
    width:85%;
    margin-left:7.5%;
    margin-top:30%;
    display:flex;
    flex-direction: row;
   }
  /* Text styles */
  .reviewsMainHeader{
    font-size:2em;
    font-weight: 700;
    margin:0;
    text-align: center;
  }
  .reviewsActualReviewText {
    width: 85%;
    margin-top:5%;
    margin-left:7.5%;
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
    height: 45%;
  }
  .reviewNameAndTwitterHandleContainerLeftColumnHandle{
    font-size:1.15em;
    font-weight:400;
    margin-top:0;
    color:#2c2c2c;
  }
}

@media only screen and (max-width: 1024px) and (min-width: 471px){
  .reviewsContainer {
    width: 80%;
    margin-left:10%;
    margin-top:3%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }
  .reviewsLeftColumnContainer {
    width:100%;
  }
  .reviewsRightColumnContainer{
    width: 100%;
  }
  .swiperContainerBox {
    position: relative;
    margin-top:5%;
  }
  .reviewsCardContainer {
    width:100%;
    border-radius: 20px;
    background-color:#e6e6e6;
    height: 41vh;
  }
  .reviewNameAndTwitterHandleContainer {
    width:85%;
    margin-left:7.5%;
    margin-top:3%;
    display:flex;
    flex-direction: row;
   }
  /* Text styles */
  .reviewsMainHeader{
    font-size:2em;
    font-weight: 700;
    margin:0;
    text-align: center;
  }
  
  .reviewsActualReviewText {
    width: 85%;
    margin-top:5%;
    margin-left:7.5%;
    font-weight: 400;
    font-size:1.15em;
    color: #9d8fa4;
    height: 45%;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1025px){
  .reviewsLeftColumnContainer {
    /* width:33%; */
    width: 45%;
  }
  .reviewsRightColumnContainer{
    /* width: 64%; */
    width: 40%;
  }
  .reviewsCardContainer {
    width:100%;
    border-radius: 20px;
    background-color:#e6e6e6;
    height: 46vh;
  }
  .reviewNameAndTwitterHandleContainer {
   width:85%;
   margin-left:7.5%;
   margin-top:2.5%;
   display:flex;
   flex-direction: row;
  }
}
/* ----------- End of Reviews Styles ---------- */

/* ----------- Footer Styles -----------*/
.footerMainContainer {
  width:100%;
}
.footerContainerMobile {
  display: none;
}
.footerContainer {
  width: 80%;
  margin-left:10%;
  margin-top:3%;
}
.footerContainerFirstRow{
  width:100%;
  margin-left:0;
  display:flex;
  flex-direction: row;
}
.footerFirstRowLeftColumn {
  width:12.5%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.footerFirstRowRightColumn {
  margin-left:0.75%;
  width:17%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.footerLinkStyleOuter {
  margin-right: 0;
  padding-right:0;
  width: 50%;
}
/* Outter buttons style */
.footerIndividualLinksButtonOutter {
  width:100%;
  height: 100%;
  display:flex;
  flex-direction: row;
  align-items: bottom;
  bottom:0;
  justify-content: flex-start;
  border-style: none;
  background-color: transparent;
  padding-left:0;
  padding-right: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}
.footerIndividualLinksButtonOutter {
  cursor: pointer;
}
.footerPortfolioTextContainer {
  width:auto;
  display: flex;
  align-self: flex-end;
}
.footerArrowMainContainer {
  width:18%;
  display: flex;
  align-self: flex-end;
}
.footerArrowContainer{
  width:100%;
  padding-right:0;
  margin-right: 0;
}
.footerArrow {
  width:100%;
}
/* Second row styles */
.footerMainContainerBottomRow {
  width: 100;
  display:flex;
  height: auto;
  flex-direction: row;
}
.footerRowLeftColumn {
  margin-top:1.5%;
  width: 37.5%;
  margin-left:0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.footerIndividualLinksButton {
  width:auto;
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  border-style: none;
  background-color: transparent;
  padding-left:0;
  padding-right: 0;
  margin-right: 0;
  padding-bottom: 0;
}
.footerIndividualLinksButton:hover {
  cursor: pointer;
}
.footerContainerMainRightColumn{
  width:40%;
  margin-left:22.5%;
  margin-bottom: 0;
  padding-bottom:0;
  position: relative;
}

/* HR styles */
.footerHR{
  background-color:black;
  border-color: black;
  border-style: solid;
  border-width: 0.02em;
}
.footerHRVertical {
  background-color:black;
  border-color: black;
  border-style: solid;
  border-width: 0.02em;
  margin-right:1em;
}
/* Icons Styles */
.footerIconLinkStyles {
  width:100%;
}
.footerTelegramIconContainer {
  width:26%;
  display: flex;
  justify-content: center;
}
.footerTelegramIconContainer:hover {
  cursor: pointer;
}
.footerTelegramIconImg {
  margin: auto;
  display: block;
  width:100%;
}

/* Text Styles */
.footerPagesLinkHeaderOutter {
  font-size:1.15em;
  font-weight: 500;
  margin:0;
  color: #9d8fa4;
  padding-bottom: 0%;
  margin-bottom: 0!important;
  padding-top:0%;
  align-self: flex-end;
  margin-top:10%;
  margin-bottom: -15%;
}
.footerPagesLinkHeaderOutter:hover {
  color: rgba(27,7,44,1);
}
.footerPagesLinkHeader {
  color: #9d8fa4;
  font-size: 1.15em;
  padding-right:1em;
  font-weight: 400;
  margin-left:0;
  padding-left:0;
  padding-bottom: 0;
  margin-bottom: 0;
  margin-top:0;
}
.footerIndividualLinksButton:hover .footerPagesLinkHeader{
  color: rgba(27,7,44,1);
}
.footerAllRightsReserved{
  position: absolute;
  font-size: 2em;
  font-weight:600;
  margin:0;
  padding:0;
  text-align: right;
  bottom:0;
  right:0;
}

/* ----------- End of Footer Styles ---------- */

/* Media queries navBar */
@media only screen and (max-width: 480px) {
  .navBarMainContainer {
    display:none;
  }
  .navBarMobileMainContainer {
    display: block;
    width:90%;
    margin-left:5%;
    position:fixed;
    background-color: #efefef;
    z-index:500;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: white;
    display:flex;
    flex-direction: row;
    height: 55px;
    /* border-color: black; */
  }
  .mobileNavBarLogoContainer {
    width:20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border-style: solid;
    border-color: red; */
  }
  .navBarLogoMobile {
    width:70%!important;
    height: 85%;
    margin-top:5%;
  }
  .navBarLogoTextMobile {
    height: 85%;
    margin-top:7%;
    margin-bottom: 0;
    padding:0;
  }
  .navBarMobileHamburgerIconContainer {
    width:50%;
    margin-left:30%;
    border-style: none;
    background-color: transparent;
    /* border-style: solid;
    border-color: green; */
  }
  .menu-container {
    display: block;
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    background-color: #efefef;
    padding:0;
    box-sizing: border-box;
    text-align: center;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
    z-index:400;
  }
  .menu-container.active {
    transform: translateY(0);  
  }
  .stupidHeaderClass {
    margin-top:0;
    padding-top:0;
  }
  /* Button Styles */
  .navBarIndividualLinksButton {
    width:90%;
    margin-left:5%;
    display:flex;
    flex-direction: row;
    align-items: bottom;
    justify-content: flex-start;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right: 0;
    margin-right: 0;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width:1px;
  }

  /* Hamburger icon styles */
  #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
    width: 33px;
    height: 15px;
    position: relative;
    margin: 15px auto;
    margin-top:0;
    margin-right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: rgba(27,7,44,1);
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }
  
  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 10px;
  }
  
  #nav-icon3 span:nth-child(4) {
    top: 20px;
  }
  
  #nav-icon3.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  
  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}

@media only screen and (max-width: 769px) and (min-width: 481px){
  .navBarMainContainer {
    display:none;
  }
  .navBarMobileMainContainer {
    display: block;
    width:80%;
    margin-left:10%;
    position:fixed;
    background-color: #efefef;
    z-index:500;
    border-bottom-style: solid;
    border-bottom-width: 1.5px;
    border-bottom-color: white;
    display:flex;
    flex-direction: row;
    height: 55px;
    /* border-color: black; */
  }
  .mobileNavBarLogoContainer {
    width:20%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    /* border-style: solid;
    border-color: red; */
  }
  .navBarLogoMobile {
    width:70%!important;
    height: 85%;
    margin-top:5%;
  }
  .navBarLogoTextMobile {
    height: 85%;
    margin-top:7%;
    margin-bottom: 0;
    padding:0;
  }
  .navBarMobileHamburgerIconContainer {
    width:50%;
    margin-left:30%;
    border-style: none;
    background-color: transparent;
    /* border-style: solid;
    border-color: green; */
  }
  .menu-container {
    display: block;
    position: fixed;
    top: 55px;
    left: 0;
    width: 100%;
    background-color: #efefef;
    padding:0;
    box-sizing: border-box;
    text-align: center;
    transform: translateY(-100%);
    transition: transform 0.5s ease-in-out;
    z-index:400;
  }
  .menu-container.active {
    transform: translateY(0);  
  }
  .stupidHeaderClass {
    margin-top:0;
    padding-top:0;
  }
  /* Button Styles */
  .navBarIndividualLinksButton {
    width:80%;
    margin-left:10%;
    display:flex;
    flex-direction: row;
    align-items: bottom;
    justify-content: flex-start;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right: 0;
    margin-right: 0;
    border-bottom-style: solid;
    border-bottom-color: white;
    border-bottom-width:1px;
  }

  /* Hamburger icon styles */
  #nav-icon1, #nav-icon2, #nav-icon3, #nav-icon4 {
    width: 33px;
    height: 15px;
    position: relative;
    margin: 15px auto;
    margin-top:0;
    margin-right: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
  }
  
  #nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
    display: block;
    position: absolute;
    height: 5px;
    width: 100%;
    background: rgba(27,7,44,1);
    border-radius: 5px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  #nav-icon3 span:nth-child(1) {
    top: 0px;
  }
  
  #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
    top: 10px;
  }
  
  #nav-icon3 span:nth-child(4) {
    top: 20px;
  }
  
  #nav-icon3.open span:nth-child(1) {
    top: 10px;
    width: 0%;
    left: 50%;
  }
  
  #nav-icon3.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
  #nav-icon3.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #nav-icon3.open span:nth-child(4) {
    top: 18px;
    width: 0%;
    left: 50%;
  }
}
/* Footer Media Query Styles */
@media only screen and (max-width: 480px) {
  .footerContainer {
    display: none;
  }
  .footerContainerMobile {
    display:block;
    width:90%;
    margin-left:5%;
  }
  /* First row Styles */
  .footerMobileFirstRow{
    width:100%;
    display:flex;
    flex-direction: row;
    margin-top:10%;
  }
  .footerMobileLeftColumn {
    width:35%;
  }
  .footerMobileInnerButtons {
    width:auto;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 4%;
  }

  .footerMobileRightColumn {
    width:35%;
  }
  .footerMobileSecondRow {
    width: 100%;
  }
  /* Outter buttons style */
  .footerLinkStyleOuter {
    margin-right: 0;
    padding-right:0;
    width: 50%;
  }
  .footerIndividualLinksButtonOutter {
    width:100%;
    height: auto;
    display:flex;
    flex-direction: row;
    border-style: none;
    background-color: transparent;
  }
  .footerIndividualLinksButtonOutter {
    cursor: pointer;
  }
  .footerPortfolioTextContainer {
    width:auto;
  }
  .footerArrowMainContainer {
    width:14%;
  }
  .footerArrowContainer{
    width:100%;
    padding-right:0;
    margin-right: 0;
  }
  .footerArrow {
    width:100%;
  }
  /* HR Styles */
  .footerMobileHR{
    width:100%;
    background-color: black;
    border-style: solid;
    border-color:black;
    margin-bottom: 3%;
  }
  /* Text styles */
  .footerMobileAllRightsReserved{
    font-size:2.2em;
    margin-bottom: 0;
  }
  .footerMobileMainHeader {
    margin-top:0%;
    font-size:1.7em;
  }
  .footerPagesLinkHeader {
    color: #9d8fa4;
    font-size: 1.4em;
    padding-right:1em;
    font-weight: 400;
    margin-left:0;
    padding-left:0;
    padding-bottom: 2%;
    margin-bottom: 0;
    margin-top:0;
  }
  .footerPagesLinkHeaderOutter {
    font-size:1.3em;
    font-weight: 500;
    margin:0;
    color: #9d8fa4;
    padding-bottom: 0%;
    margin-bottom: 0!important;
    padding-top:0%;
    align-self: flex-end;
    margin-top:10%;
    margin-bottom: 0%;
  }
}

@media only screen and (max-width: 769px) and (min-width: 481px){
  .footerContainer {
    display: none;
  }
  .footerContainerMobile {
    display:block;
    width:80%;
    margin-left:10%;
  }
  /* First row Styles */
  .footerMobileFirstRow{
    width:100%;
    display:flex;
    flex-direction: row;
    margin-top:10%;
  }
  .footerMobileLeftColumn {
    width:35%;
  }
  .footerMobileInnerButtons {
    width:auto;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    border-style: none;
    background-color: transparent;
    padding-left:0;
    padding-right: 0;
    margin-right: 0;
    padding-bottom: 4%;
  }

  .footerMobileRightColumn {
    width:35%;
  }
  .footerMobileSecondRow {
    width: 100%;
  }
  /* Outter buttons style */
  .footerLinkStyleOuter {
    margin-right: 0;
    padding-right:0;
    width: 50%;
  }
  .footerIndividualLinksButtonOutter {
    width:100%;
    height: auto;
    display:flex;
    flex-direction: row;
    border-style: none;
    background-color: transparent;
  }
  .footerIndividualLinksButtonOutter {
    cursor: pointer;
  }
  .footerPortfolioTextContainer {
    width:auto;
  }
  .footerArrowMainContainer {
    width:8%;
  }
  .footerArrowContainer{
    width:100%;
    padding-right:0;
    margin-right: 0;
  }
  .footerArrow {
    width:100%;
  }
  /* HR Styles */
  .footerMobileHR{
    width:100%;
    background-color: black;
    border-style: solid;
    border-color:black;
    margin-bottom: 3%;
  }
  /* Text styles */
  .footerMobileAllRightsReserved{
    font-size:2.2em;
    margin-bottom: 0;
  }
  .footerMobileMainHeader {
    margin-top:0%;
    font-size:1.7em;
  }
  .footerPagesLinkHeader {
    color: #9d8fa4;
    font-size: 1.4em;
    padding-right:1em;
    font-weight: 400;
    margin-left:0;
    padding-left:0;
    padding-bottom: 2%;
    margin-bottom: 0;
    margin-top:0;
  }
  .footerPagesLinkHeaderOutter {
    font-size:1.3em;
    font-weight: 500;
    margin:0;
    color: #9d8fa4;
    padding-bottom: 0%;
    margin-bottom: 0!important;
    padding-top:0%;
    align-self: flex-end;
    margin-top:10%;
    margin-bottom: 0%;
  }
}